<template>
    <div class="fill-height pa-5 pa-sm-10" v-if="AppStore.started_app" id="comment-page">
        <v-container :class="commentFieldFocus ? '' : 'fill-height' +' d-flex align-center flex-row'">
            <v-row class="">
                <v-card elevation="0" class="mx-auto pa-1 pa-sm-10 mb-5" rounded="xl">

                    <v-col cols="12" class="text-h2 pt-5 pb-7 text-center" id="comment-title"
                           v-html="AppStore.data.configuration[AppStore.locale].comment_title">
                    </v-col>

                    <v-col cols="12 text-center" v-if="outlinedTextArea">
<!--                        <v-alert-->
<!--                            dense-->
<!--                            text-->
<!--                            outlined-->
<!--                            id="comment-help"-->
<!--                            class="mb-0"-->
<!--                            v-if="outlinedTextArea"-->
<!--                            v-html="AppStore.data.configuration[AppStore.locale].comment_help"-->
<!--                            :color="Helpers.getColor(AppStore.data.general.secondary_color.hexa)"-->
<!--                        >-->
<!--                        </v-alert>-->
                        <div id="comment-help" v-html="AppStore.data.configuration[AppStore.locale].comment_help"
                             :style="'color:'+AppStore.data.general.secondary_color.hexa"></div>
                    </v-col>

                    <v-col cols="12" class="">
<!--                        <v-col cols="12" v-if="AppStore.comment_profil_questions.length" id="comment-profil-questions">-->
<!--                            <div v-for="(question, questionIndex) in AppStore.profil_questions" :key="questionIndex">-->
<!--                                <v-divider v-if="question.format === 'divider'"></v-divider>-->
<!--                                <slider-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"-->
<!--                                             v-if="question.format === 'slider'"-->
<!--                                             v-model="AppStore.profil_questions[questionIndex]"-->
<!--                                             :questionIndex="questionIndex" :lang="AppStore.locale"></slider-view>-->
<!--                                <text-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"-->
<!--                                           v-if="question.format === 'text'"-->
<!--                                           v-model="AppStore.profil_questions[questionIndex]"-->
<!--                                           :questionIndex="questionIndex"-->
<!--                                           :lang="AppStore.locale"></text-view>-->
<!--                                <textarea-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"-->
<!--                                               v-if="question.format === 'textarea'"-->
<!--                                               v-model="AppStore.profil_questions[questionIndex]"-->
<!--                                               :questionIndex="questionIndex" :lang="AppStore.locale"></textarea-view>-->
<!--                                <select-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"-->
<!--                                             v-if="question.format === 'select'"-->
<!--                                             v-model="AppStore.profil_questions[questionIndex]"-->
<!--                                             :questionIndex="questionIndex" :lang="AppStore.locale"></select-view>-->
<!--                                <checkbox-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"-->
<!--                                               v-if="question.format === 'checkbox'"-->
<!--                                               :color="AppStore.data.general.primary_color"-->
<!--                                               v-model="AppStore.profil_questions[questionIndex]"-->
<!--                                               :questionIndex="questionIndex" :lang="AppStore.locale"></checkbox-view>-->
<!--                                <radio-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"-->
<!--                                            v-if="question.format === 'radio'"-->
<!--                                            :color="AppStore.data.general.primary_color"-->
<!--                                            v-model="AppStore.profil_questions[questionIndex]"-->
<!--                                            :questionIndex="questionIndex" :lang="AppStore.locale"></radio-view>-->
<!--                                <switch-view :class="{ 'd-none' : !question.mustBeShow }" :ref="'qi'+question.id"-->
<!--                                             v-if="question.format === 'switch'"-->
<!--                                             :color="AppStore.data.general.primary_color"-->
<!--                                             v-model="AppStore.profil_questions[questionIndex]"-->
<!--                                             :questionIndex="questionIndex" :lang="AppStore.locale"></switch-view>-->
<!--                            </div>-->
<!--                        </v-col>-->


                        <input
                            type="text"
                            v-autowidth="{maxWidth: '960px', minWidth: '20px', comfortZone: 0}"
                            class="openKeyboardBtn text-button mb-5"
                            @click="$refs.comment.focus(); outlinedTextArea = true"
                            :style="'border-color:'+Helpers.getColor(AppStore.data.general.primary_color.hexa)+';background-color:'+Helpers.getColor(AppStore.data.general.primary_color.hexa)"
                            :placeholder="AppStore.data.configuration[AppStore.locale].comment_keyboard_btn_text"
                            v-if="!outlinedTextArea"
                        />

                        <v-textarea
                            v-model="AppStore.rating.comment"
                            :placeholder="_.includes([0,1,2], AppStore.rating.rate) ? AppStore.data.configuration[AppStore.locale].comment_negative_placeholder : AppStore.data.configuration[AppStore.locale].comment_positive_placeholder"
                            persistent-placeholder
                            outlined
                            @focus="outlinedTextArea = true; commentFieldFocus = true"
                            ref="comment"
                            id="comment-textarea"
                            rows="9"
                            hide-details
                            class="mb-3"
                            name="comment">

                        </v-textarea>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-btn
                            v-if="AppStore.rating.comment.trim().length < AppStore.data.configuration.comment_chars_required_number"
                            @click="submit" rounded
                            outlined elevation="0"
                            :large="AppStore.data.general.button_size === 'large'"
                            :x-large="AppStore.data.general.button_size === 'x-large'"
                            :x-small="AppStore.data.general.button_size === 'x-small'"
                            :small="AppStore.data.general.button_size === 'small'"
                            :color="AppStore.data.general.primary_color.hexa" class="revert-btn text-button"
                            :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
                            v-html="AppStore.data.configuration[AppStore.locale].comment_retry_btn_text">
                        </v-btn>
                        <v-btn
                            v-if="AppStore.rating.comment.trim().length >= AppStore.data.configuration.comment_chars_required_number"
                            @click="submit" rounded elevation="0" dark
                            :large="AppStore.data.general.button_size === 'large'"
                            :x-large="AppStore.data.general.button_size === 'x-large'"
                            :x-small="AppStore.data.general.button_size === 'x-small'"
                            :small="AppStore.data.general.button_size === 'small'"
                            @keypress.enter="navigate"
                            class="text-button"
                            :color="AppStore.data.general.primary_color.hexa"
                            :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
                            v-html="AppStore.data.configuration[AppStore.locale].comment_validate_btn_text">
                        </v-btn>
                    </v-col>
                </v-card>
            </v-row>
        </v-container>
    </div>

</template>

<script>
import VTextAreaWithValidation from "../../../components/communs/inputs/VTextAreaWithValidation.vue";
import AppStore from "../../../stores/AppStore";
import _ from 'lodash';
import VueInputAutowidth from 'vue-input-autowidth'
import Vue from "vue";
import Stepper from "../../../components/communs/Stepper.vue";
import Helpers from "../../../services/Helpers";
import RadioView from "../../../components/communs/inputViewer/RadioView.vue";
import TextView from "../../../components/communs/inputViewer/TextView.vue";
import SelectView from "../../../components/communs/inputViewer/SelectView.vue";
import CheckboxView from "../../../components/communs/inputViewer/CheckboxView.vue";
import SwitchView from "../../../components/communs/inputViewer/SwitchView.vue";
import SliderView from "../../../components/communs/inputViewer/SliderView.vue";
import TextareaView from "../../../components/communs/inputViewer/TextareaView.vue";
import {EventBus} from "../../../services/Request";

Vue.use(VueInputAutowidth)
export default {
    name: 'comment-view',

    components: {
        TextareaView, SliderView, SwitchView, CheckboxView, SelectView, TextView, RadioView,
        VTextAreaWithValidation,
        Stepper
    },

    props: {},

    data: () => ({
        AppStore,
        Helpers,
        outlinedTextArea: false,
        questions: [],
        appreciation_questions_to_display: [],
        commentFieldFocus: false,
    }),

    created() {

    },

    async mounted() {

    },

    computed: {
        _() {
            return _;
        }
    },

    watch: {},

    methods: {
        submit() {
            this.commentFieldFocus = false
            if(AppStore.data.configuration.skip_questions_page && !AppStore.data.configuration.skip_timeline_page){
                EventBus.$emit('storeRating', 'noReset')
                this.$router.push({name: 'tb-1-timeline'})
            } else if(AppStore.data.configuration.skip_questions_page && AppStore.data.configuration.skip_timeline_page) {
	            EventBus.$emit('storeRating', 'noReset')
	            this.$router.push({name: 'tb-1-home'})
			} else {
                this.$router.push({name: 'tb-1-questions'})
            }
        },
    }
};
</script>
<style scoped lang="scss">
.openKeyboardBtn {
    margin: 0 auto;
    display: block;
    min-width: 64px;
    height: 44px;
    padding: 0 16px;
    background-color: rgb(252, 119, 83);
    border-color: rgb(252, 119, 83);
    font-size: 1.2rem;
    color: white !important;
    border-radius: 28px;
    caret-color: transparent;
    font-weight: 700;
    letter-spacing: 0.0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
}

.openKeyboardBtn::placeholder {
    color: white !important;
}
</style>
